<template>
  <div class="more" ref="container">
    <ul
      class="main-container"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="0"
      infinite-scroll-immediate-check="false"
    >
      <li v-for="(item, i) in teacherList" :key="i">
        <img :src="item.avatar" alt="item.nickname" />
        <div class="text">
          <h4>{{ item.nickname }}</h4>
          <p>
            {{ item.position }}
            <br />
            <span>粉丝数：{{ item.care_num }}</span>
          </p>
        </div>
        <mt-button
          :type="item.is_care == 1 ? 'default' : 'primary'"
          size="small"
          @click="care(item.uid, item.is_care == 1 ? 0 : 1, i)"
        >{{ item.is_care == 1 ? "已关注" : "关注" }}</mt-button>
      </li>
    </ul>

    <Loading :loaded="loadend" :loading="loading"></Loading>
  </div>
</template>

<script>
import Loading from "@components/Loading";
import { getCommunityTeacher, getCommunityCare } from "@api/community";

export default {
  name: "more",
  components: {
    Loading,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      teacherList: [],
      queryLoading: false,
      loadend: false,
      loading: false,
    };
  },
  created() {
    this.loadMore();
  },
  methods: {
    loadMore() {
      let that = this;

      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      getCommunityTeacher(that.page, that.limit).then((res) => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.teacherList.push.apply(that.teacherList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
      });
    },

    //关注 status 1关注，0取关
    care(id, status, i) {
      let that = this;

      getCommunityCare({
        uid: id,
        status: status,
      })
        .then((res) => {
          that.$dialog.success(res.msg);
          that.$set(
            that.teacherList[i],
            "is_care",
            that.teacherList[i].is_care == 1 ? 0 : 1
          );
          that.$set(
            that.teacherList[i],
            "care_num",
            that.teacherList[i].is_care == 1
              ? ++that.teacherList[i].care_num
              : --that.teacherList[i].care_num
          );
        })
        .catch((res) => {
          that.$dialog.error(res.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  ul li {
    width: 100%;
    height: 1.4rem;
    padding: 0.2rem 0.3rem;
    border-bottom: 0.02rem solid #a9a9a9;
    display: flex;
    justify-content: space-between;
    position: relative;
    img {
      height: 0.6rem;
      width: 0.6rem;
      border-radius: 100%;
      position: absolute;
      top: 0.2rem;
      left: 0.3rem;
    }
    button {
      margin-top: 0.2rem;
      height: 0.6rem;
      font-size: 0.26rem;
    }
    .text {
      padding-left: 0.8rem;
      h4 {
        font-size: 0.26rem;
      }
      p {
        font-size: 0.24rem;
        span {
          color: #a9a9a9;
        }
      }
    }
  }
}
</style>
